<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			left
			v-model="projectCard"
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-cog-sync</v-icon>Project</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Project</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="projectCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<div>
					<TextInput hide-details outlined v-model="search_project" placeholder="Serach Poject">
					</TextInput>
				</div>
			</v-list>
			<v-list two-line class="px-3">
				<span class="">Board Project</span>
				<v-list-item-group v-model="selectedMember" active-class="blue--text" multiple>
					<template v-for="(item, index) in projectList">
						<v-list-item class="px-0" :key="item.title">
							<template v-slot:default="{ active }">
								<v-list-item-avatar class="mx-2" size="30" :color="item.color">
									<span class="text-h6 black--text">{{ item.projectTitle[0] }}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										style="text-transform: capitalize !important; color: grey !important"
										v-text="item.projectTitle"
									></v-list-item-title>
									<v-list-item-subtitle>
										{{ item.projectDate }}
										<v-chip color="cyan" outlined label x-small class="ma-1">{{ item.serviceType }}</v-chip>
									</v-list-item-subtitle>
								</v-list-item-content>

								<!-- <v-list-item-chip>
									<v-icon v-if="!active" color="grey lighten-1"></v-icon>
									<v-icon v-else color="blue darken-3" small> mdi-check </v-icon>
								</v-list-item-chip> -->
							</template>
						</v-list-item>

						<v-divider v-if="false" :key="index"></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
			<v-list class="px-2 card-member">
				<div>
					<v-btn class="w-100" @click="showmembers" block depressed>Show other Workspace Project</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	data() {
		return {
			projectCard: false,
			showmembers: false,
			selectedMember: [1],
			search_project: "",
			projectList: [
				{
					id: 1,
					projectId: "BP-0000001",
					projectTitle: "Bthrust :- BTC-000001",
					serviceType: "SEO",
					projectDate: "19/11/2023 12:00AM",
					color: "orange",
				},
				{
					id: 2,
					projectId: "BP-0000002",
					projectTitle: "Bthrust :- BTC-000002",
					serviceType: "Domain",
					projectDate: "20/11/2023 12:00AM",
					color: "blue",
				},
			],
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
