<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			v-model="meetingCard"
			offset-y
			left
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-handshake-outline</v-icon>Meeting</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Meeting</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="meetingCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<div>
					<TextInput hide-details outlined v-model="search_member" placeholder="Serach meeting">
					</TextInput>
				</div>
			</v-list>
			<v-list two-line class="px-3">
				<span class="">Board Meeting</span>
				<v-list-item-group v-model="selectedMember" active-class="blue--text" multiple>
					<template v-for="(item, index) in meetingList">
						<v-list-item class="px-0" :key="item.title">
							<template v-slot:default="{ active }">
								<v-list-item-avatar cover size="30" :color="item.color">
									<img v-if="item.attendees" :src="item.attendees" alt="John" />
									<span v-else class="text-h6 black--text">{{ item.title[0] }}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										style="text-transform: capitalize !important; color: grey !important"
										v-text="item.title"
									></v-list-item-title>
									<v-list-item-subtitle v-text="item.date"></v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-content>
									<!-- <v-icon v-if="!active" color="grey lighten-1"></v-icon>
									<v-icon v-else color="blue darken-3" small> mdi-check </v-icon> -->
									<v-chip color="green" outlined label>{{ item.meetingType }}</v-chip>
								</v-list-item-content>
							</template>
						</v-list-item>

						<v-divider v-if="false" :key="index"></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
			<v-list class="px-2 card-member">
				<div>
					<v-btn class="w-100" @click="showmembers" block depressed>Show other Workspace Meeting</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	data() {
		return {
			meetingCard: false,
			showmembers: false,
			selectedMember: [1],
			search_member: "",
			meetingList: [
				{
					id: 1,
					attendees: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
					title: "title name",
					date: "19/11/2023 12:00 AM",
					meetingType: "F2F Client",
					color: "orange",
				},
				{
					id: 2,
					attendees: "https://cdn.vuetifyjs.com/images/john.jpg",
					title: "title2",
					date: "20/11/2023 12:00 AM",
					meetingType: "F2F Client",
					color: "blue",
				},
			],
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
