<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			left
			v-model="leadCard"
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-account-group-outline</v-icon>Leads</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Leads</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="leadCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<div>
					<TextInput hide-details outlined v-model="search_lead" placeholder="Serach Lead"> </TextInput>
				</div>
			</v-list>
			<v-list two-line class="px-3">
				<span class="">Lead</span>
				<v-list-item-group v-model="selectedMember" active-class="blue--text" multiple>
					<template v-for="(item, index) in leadList">
						<v-list-item class="px-0" :key="item.title">
							<template v-slot:default="{ active }">
								<v-list-item-avatar size="30" :color="item.color">
									<span class="text-h6 black--text">{{ item.companyName[0] }}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title
										style="text-transform: capitalize !important; color: grey !important"
										v-text="item.companyName"
									></v-list-item-title>
									<v-list-item-subtitle v-text="item.companyEmail"></v-list-item-subtitle>
								</v-list-item-content>

								<v-list-item-content small>
									<v-chip color="cyan" label class="ma-1" x-small text-color="white">{{
										item.leadtId
									}}</v-chip>
									<v-chip color="green" label class="mx-1" x-small text-color="white">{{
										item.leadDate
									}}</v-chip>
									<!-- <v-icon v-if="!active" color="grey lighten-1"></v-icon>
									<v-icon v-else color="blue darken-3" small> mdi-check </v-icon> -->
								</v-list-item-content>
							</template>
						</v-list-item>

						<v-divider v-if="false" :key="index"></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
			<v-list class="px-2 card-member">
				<div>
					<v-btn class="w-100" @click="showmembers" block depressed>Show other Workspace Lead</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "MemberCard",
	data() {
		return {
			leadCard: false,
			showmembers: false,
			selectedMember: [1],
			search_lead: "",
			leadList: [
				{
					id: 1,
					leadtId: "BT-0000001",
					companyName: "Bthrust pvt ltd",
					companyEmail: "bthrust@gmail.com",
					leadDate: "19/11/2023",
					color: "orange",
				},
				{
					id: 2,
					leadtId: "BT-0000001",
					companyName: "ABC pvt ltd",
					companyEmail: "abc@gmail.com",
					leadDate: "20/11/2023",
					color: "blue",
				},
			],
		};
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
