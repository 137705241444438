<template>
	<div class="members">
		<v-menu
			:nudge-width="300"
			max-width="300"
			v-model="memebresCard"
			offset-y
			:close-on-content-click="false"
			class="custom_font_family"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-if="createMember" depressed icon small v-bind="attrs" v-on="on">
					<v-avatar color="grey lighten-2" size="32"
						><span class="white--black text-h6">+</span></v-avatar
					>
				</v-btn>
				<v-btn v-else v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-account-outline</v-icon>Members</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Members</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="memebresCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2">
				<div>
					<TextInput hide-details outlined v-model="search_members" placeholder="Serach Members">
					</TextInput>
				</div>
			</v-list>
			<v-list two-line class="px-3">
				<span>Board members</span>
				{{ selectedMember }}
				<v-list-item-group v-model="selectedMember" active-class="blue--text" multiple>
					<template v-for="(item, index) in boardMembers">
						<v-list-item :key="item.title" style="height: 40px" @click="onClickMember(index)">
							<template v-slot:default="{ active }">
								<v-list-item-avatar size="30" :color="item.color">
									<span class="text-h6 black--text title_card">{{ item.title[0] }}</span>
								</v-list-item-avatar>

								<v-list-item-content>
									<v-list-item-title style="text-transform: capitalize !important; color: grey !important"
										>{{ item.title }}({{ item.email }})</v-list-item-title
									>
								</v-list-item-content>

								<v-list-item-action>
									<v-icon v-if="!active" color="grey lighten-1"></v-icon>
									<v-icon v-else color="blue darken-3" small> mdi-check </v-icon>
								</v-list-item-action>
							</template>
						</v-list-item>

						<v-divider v-if="false" :key="index"></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
			<v-list class="px-2 card-member">
				<div class="">
					<v-btn @click="showmembers" class="w-100" block depressed>Show other Workspace Members</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	props: ["createMember"],
	name: "MemberCard",
	data() {
		return {
			memebresCard: false,
			showmembers: false,
			selectedMember: [0],
			search_members: "",
			boardMembers: [
				{
					id: 1,
					title: "John Doe",
					email: "johndoe@12",
					color: "orange",
				},
				{
					id: 2,
					title: "Scrott",
					email: "scrott@12",
					color: "blue",
				},
			],
		};
	},
	methods: {
		onClickMember(index) {
			// Emit an event to notify the parent component of the color change
			this.$emit("add-member", this.boardMembers[index]);
		},
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.custom_font_family {
	/* color: #172b4d; */
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400;
	line-height: 20px;
}
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 13px !important;
}
</style>
