<template>
	<div class="add-card_btn">
		<v-menu
			:nudge-width="300"
			max-width="300"
			left
			v-model="copyCard"
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-content-copy</v-icon>Copy</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card title_card">Copy card</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="copyCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2 card-member">
				<div class="">
					<label class="input-label"><v-icon small>mdi-stars</v-icon>Title</label>
					<TextAreaInput
						hide-details
						:disabled="pageLoading"
						:loading="pageLoading"
						id="description"
						placeholder="Title"
						v-model="title"
					></TextAreaInput>
				</div>
				<div class="">
					<label class="input-label">Keep..</label>
					<template v-for="(row, index) in keepCheckBox">
						<v-checkbox
							:key="index"
							hide-details
							class="mx-0 my-0"
							v-model="row.checklists"
							:label="row.titleLabel"
						></v-checkbox>
					</template>
				</div>
				<div class="pt-2">
					<label class="input-label">Copy to..</label>
					<v-select
						hide-details
						v-model="designation"
						:items="designationList"
						attach
						chips
						label="Project"
						class="rounded"
						style="background-color: rgb(238, 231, 231)"
					></v-select>
					<div class="d-flex pt-2">
						<v-select
							hide-details
							v-model="list"
							:items="moveList"
							attach
							chips
							label="List"
							class="rounded me-2"
							style="background-color: rgb(238, 231, 231)"
						></v-select>
						<v-select
							hide-details
							v-model="position"
							:items="positionList"
							attach
							chips
							label="Position"
							class="rounded"
							style="background-color: rgb(238, 231, 231)"
						></v-select>
					</div>
				</div>

				<div class="pt-3">
					<v-btn color="blue darken-2" class="white--text">Create card</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
import TextAreaInput from "@/view/components/TextAreaInput";
export default {
	name: "MemberCard",
	data() {
		return {
			pageLoading: null,
			copyCard: false,
			title: "Project Planing",
			designation: "John doe",
			list: "To do",
			position: 1,
			designationList: ["John doe", "Maxwell"],
			positionList: [1, 2, 3, 4],
			moveList: ["To do", "Doing", "done", "buzz"],
			// checklists: true,
			// labels: true,
			// attachments: true,
			// comments: true,
			keepCheckBox: [
				{
					id: 1,
					checklists: false,
					titleLabel: "Checklists(1)",
				},
				{
					id: 2,
					checklists: false,
					titleLabel: "Labels(1)",
				},
				{
					id: 3,
					checklists: false,
					titleLabel: "Attachments(1)",
				},
				{
					id: 4,
					checklists: false,
					titleLabel: "Comments(1)",
				},
			],
		};
	},
	components: {
		TextAreaInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	justify-content: flex-start !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
}
</style>
