<template>
	<div class="btn-custom">
		<v-list dense class="hoverlist py-1 mt-2 pointer">
			<template v-for="(item, index) in attachmentItems">
				<v-divider v-if="item.divider" :key="index" :inset="item.inset"></v-divider>
				<v-list-item class="px-0" v-else :key="item.title">
					<v-img max-height="100" max-width="100" :src="item.avatar"></v-img>

					<v-list-item-content class="ps-2">
						<v-list-item-title style="color: #000 !important; text-transform: lowercase !important"
							><span>{{ item.title }}</span
							><v-icon size="15" class="me-1" @click.stop.prevent="openImageInNewTab"
								>mdi-arrow-top-right</v-icon
							></v-list-item-title
						>
						<v-list-item-subtitle style="font-size: 13px !important; color: #000 !important"
							><span>{{ item.subtitle }}</span>
							<span><v-icon size="15">mdi-circle-small</v-icon></span>
							<v-btn
								class="text-decoration-underline"
								style="min-width: 20px !important; padding: 0px !important"
								plain
								>Comments</v-btn
							>
							<span><v-icon size="15">mdi-circle-small</v-icon></span>
							<v-menu v-model="deleteAttachment" offset-y top :close-on-content-click="false">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										style="min-width: 20px !important; padding: 0px !important"
										class="text-decoration-underline"
										plain
										@click.stop.prevent="deleteAttachment"
										>Delete</v-btn
									>
								</template>
								<v-toolbar color="blue darken-4" dark>
									<div class="text-h6 text-center">Delete attachment?</div>
									<v-spacer></v-spacer>
									<v-btn class="me-1" @click="deleteAttachment = false" small depressed icon
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-toolbar>
								<v-list class="px-2">
									<v-list-item>
										<v-list-item-content>
											Deleting a comment is forever. There is no<br />
											undo.
										</v-list-item-content>
									</v-list-item>
									<v-list-item>
										<v-list-item-content>
											<v-btn color="red" class="white--text my-2" block>Delete</v-btn>
										</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
							<span><v-icon size="15">mdi-circle-small</v-icon></span>
							<v-menu v-model="editAttachment" offset-y top :close-on-content-click="false">
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs"
										v-on="on"
										style="min-width: 20px !important; padding: 0px !important"
										plain
										class="text-decoration-underline"
										@click.stop.prevent="editAttachment = true"
										>Edit</v-btn
									>
								</template>
								<v-toolbar color="blue darken-4" dark>
									<div class="text-h6 text-center">Edit attachment</div>
									<v-spacer></v-spacer>
									<v-btn class="me-1" @click="editAttachment = false" small depressed icon
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-toolbar>
								<v-list class="px-2">
									<v-form>
										<div class="">
											<label class="input-label">Link name</label>
											<TextInput v-model="link_name" hide-details outlined class="mt-0"></TextInput>
											<v-btn color="blue darken-4" class="white--text mt-3">Update</v-btn>
										</div>
									</v-form>
								</v-list>
							</v-menu>
						</v-list-item-subtitle>
						<span
							class="text-decoration-underline"
							plain
							@click.stop.prevent="show_hideCover = !show_hideCover"
							><v-icon class="mr-1" size="15">mdi-television</v-icon
							>{{ show_hideCover ? "Remove" : "Make" }} Cover</span
						>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
	</div>
</template>
<script>
import TextInput from "@/view/components/TextInput";
export default {
	name: "AddAttachment",
	data() {
		return {
			show_hideCover: false,
			deleteAttachment: false,
			editAttachment: false,
			link_name: null,
			attachmentItems: [
				{
					avatar: "https://picsum.photos/id/11/100/100",
					title: "picsum.photos/id/11/100/10",
					subtitle: "Added yesterday at 5:29 PM",
				},
			],
		};
	},
	methods: {
		openImageInNewTab() {
			window.open(this.attachmentItems.avatar, "_blank");
		},
	},
	components: {
		TextInput,
	},
};
</script>
<style scoped>
.btn-custom button {
	text-transform: capitalize !important;
}
.text_underline {
	opacity: 1 !important;
}
.btn-custom .hoverlist:hover {
	background-color: #e4e6ea;
}
</style>
