<template>
	<div class="checklist-template">
		<div class="mb-2 mt-0">
			<draggable v-model="titleFieldData" class="list_items" draggable=".drag-item">
				<div v-for="(row, index) in titleFieldData" :key="index" class="drag-item">
					<v-hover v-slot="{ hover }" :key="index + 'v-list'">
						<v-list rounded class="px-0 py-0 mb-2" style="background: none !important">
							<v-list-item-group :class="{ 'on-hover': hover }" class="d-flex">
								<v-checkbox
									multiple
									group
									class="mx-0 my-0"
									hide-details
									color="primary"
									v-model="row.checkItem"
								></v-checkbox>
								<v-list-item
									class="py-1"
									:class="!checklistEditable ? '' : ''"
									@click="checklistEditable(index)"
								>
									<template v-if="!row.checklistEditable">
										<v-list-item-content>
											<v-list-item-title
												style="
													color: #676464 !important;
													text-transform: capitalize !important;
													font-weight: 400 !important;
												"
												v-text="row.ChecklistItem"
											></v-list-item-title>
										</v-list-item-content>
										<v-list-item-action
											class="mx-0 my-0"
											:class="{ 'show-btns': hover }"
											:color="transparent"
										>
											<div class="d-flex">
												<v-menu
													:nudge-width="300"
													max-width="300"
													v-model="row.assignlistDialog"
													bottom
													origin="center center"
													transition="scale-transition"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-bind="attrs"
															v-on="on"
															:class="{ 'show-btns': hover }"
															:color="transparent"
															small
															icon
														>
															<v-icon small>mdi-clock-outline</v-icon>
														</v-btn>
													</template>
													<v-toolbar color="blue darken-4" dark>
														<subheader class="text-h6">Assign</subheader>
														<v-spacer></v-spacer>
														<v-btn @click="assignlistDialog = true" depressed icon small
															><v-icon>mdi-close</v-icon></v-btn
														>
													</v-toolbar>
													<v-list subheader class="px-4 pt-3">
														<v-form>
															<div class="pb-2">
																<label for="" class="input_label">Member</label>
																<TextInput
																	v-model="member"
																	hide-details
																	placeholder="member"
																	class="mt-0"
																></TextInput>
															</div>
															<div class="pb-2">
																<label for="" class="input_label">Start Date</label>
																<DatePicker
																	:hideTopMargin="true"
																	depressed
																	:mandatory="true"
																	placeholder="Start Date"
																	v-model="start_date"
																	:min-date="minDate"
																	:default-date="start_date"
																	:rules="[vrules.required(start_date, 'Start Date')]"
																	:class="{
																		required: !start_date,
																	}"
																></DatePicker>
															</div>
															<div class="pb-2">
																<label for="" class="input_label">Due Date</label>
																<DatePicker
																	:hideTopMargin="true"
																	depressed
																	:mandatory="true"
																	placeholder="Due Date"
																	v-model="due_date"
																	:min-date="minDate"
																	:default-date="due_date"
																	:rules="[vrules.required(due_date, 'Due Date')]"
																	:class="{
																		required: !due_date,
																	}"
																></DatePicker>
															</div>
															<div class="pb-2">
																<v-btn color="blue darken-4" class="white--text">Add</v-btn>
															</div>
														</v-form>
													</v-list>
												</v-menu>
												<v-btn :class="{ 'show-btns': hover }" :color="transparent" class="mx-1" small icon>
													<v-icon small>mdi-account-plus</v-icon>
												</v-btn>
												<v-menu
													:nudge-width="250"
													max-width="250"
													v-model="row.itemActionMenu"
													bottom
													origin="center center"
													transition="scale-transition"
												>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-bind="attrs"
															v-on="on"
															:class="{ 'show-btns': hover }"
															:color="transparent"
															small
															icon
														>
															<v-icon small>mdi-dots-horizontal</v-icon>
														</v-btn>
													</template>
													<v-toolbar color="blue darken-4" dark>
														<subheader class="text-h6">Items actions</subheader>
														<v-spacer></v-spacer>
														<v-btn @click="itemActionMenu = true" depressed icon small
															><v-icon>mdi-close</v-icon></v-btn
														>
													</v-toolbar>
													<v-list class="">
														<v-list-item v-for="(item, i) in itemsList" :key="i">
															<v-list-item-title
																style="text-transform: capitalize !important; color: #676464 !important"
																>{{ item.title }}</v-list-item-title
															>
														</v-list-item>
													</v-list>
												</v-menu>
											</div>
										</v-list-item-action>
									</template>
									<template v-else>
										<div class="mb-2">
											<TextInput
												@keydown.enter.stop.prevent="stopchecklistEditable(index)"
												v-model="row.ChecklistItem"
												hide-details
												placeholder="Add an item"
											></TextInput>
											<div class="d-flex align-center justify-space-between pt-2">
												<div class="d-flex align-center">
													<v-btn
														depressed
														color="blue darken-4"
														class="white--text mr-2"
														@click.stop.prevent="stopchecklistEditable(index)"
													>
														Add
													</v-btn>
													<v-btn depressed icon small @click.stop.prevent="stopchecklistEditable(index)"
														><v-icon>mdi-close</v-icon></v-btn
													>
												</div>
												<div class="d-flex checklist_bts">
													<v-btn depressed><v-icon left size="16">mdi-account-plus-outline</v-icon>Assign</v-btn>
													<v-btn class="mx-2" depressed
														><v-icon left size="16">mdi-clock-outline</v-icon>Due date</v-btn
													>
													<v-btn class="me-2 px-1" style="min-width: 30px !important" depressed
														><v-icon size="16">mdi-at</v-icon></v-btn
													>
													<v-btn class="px-1" depressed style="min-width: 30px !important"
														><v-icon size="16">mdi-emoticon-outline</v-icon></v-btn
													>
												</div>
											</div>
										</div>
									</template>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-hover>
				</div>
			</draggable>
			<div class="mb-4 mt-2 ms-0" v-if="isAddEnable">
				<TextInput
					@keydown.enter="addCheckListEvent()"
					v-model="textField"
					hide-details
					placeholder="Add an item"
				></TextInput>
				<div class="d-flex align-center justify-space-between pt-2">
					<div class="d-flex align-center">
						<v-btn depressed color="blue darken-4" class="white--text mr-2" @click="addCheckListEvent()">
							Add
						</v-btn>
						<v-btn depressed @click="isAddEnable = false"> canel </v-btn>
					</div>
					<div class="d-flex checklist_bts">
						<v-btn depressed><v-icon left size="16">mdi-account-plus-outline</v-icon>Assign</v-btn>
						<v-btn class="mx-2" depressed
							><v-icon left size="16">mdi-clock-outline</v-icon>Due date</v-btn
						>
						<v-btn class="me-2 px-1" style="min-width: 30px !important" depressed
							><v-icon size="16">mdi-at</v-icon></v-btn
						>
						<v-btn class="px-1" depressed style="min-width: 30px !important"
							><v-icon size="16">mdi-emoticon-outline</v-icon></v-btn
						>
					</div>
				</div>
			</div>
			<div class="" v-else>
				<v-btn @click="isAddEnable = true" depressed>Add an item</v-btn>
			</div>
		</div>
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import draggable from "vuedraggable";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
export default {
	name: "ChecklistItem",

	components: {
		TextInput,
		draggable,
		DatePicker,
	},
	data() {
		return {
			textField: null,
			isAddEnable: false,
			transparent: "rgba(255, 255, 255, 0)",
			addItemChecklist: false,
			member: null,
			start_date: null,
			due_date: null,
			minDate: new Date().toISOString().substr(0, 10),
			titleFieldData: [],
			itemsList: [{ title: "Convert to card" }, { title: "Delete" }],
		};
	},
	methods: {
		addCheckListEvent() {
			if (this.textField) {
				this.titleFieldData.push({
					ChecklistItem: this.textField,
					checkItem: false,
					checklistEditable: false,
					assignlistDialog: false,
					itemActionMenu: false,
				});
			}
			this.textField = null;
		},
		checklistEditable(index) {
			//row.checklistEditable = true;
			this.isAddEnable = false;
			this.titleFieldData.forEach((item, i) => {
				item.checklistEditable = i === index;
			});
		},
		stopchecklistEditable(index) {
			//row.checklistEditable = false;
			this.isAddEnable = false;
			this.titleFieldData[index].checklistEditable = false;
		},
	},
};
</script>
<style scoped>
.show-btns {
	color: rgb(27, 25, 25) !important;
}
.checklist-template button {
	text-transform: capitalize !important;
}
.checklist_bts button {
	background-color: transparent !important;
}
</style>
