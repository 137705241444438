<template>
	<div class="add-card_btn">
		<v-menu
			:nudge-width="300"
			max-width="300"
			v-model="moveCard"
			left
			offset-y
			:close-on-content-click="false"
		>
			<template v-slot:activator="{ on, attrs }">
				<span v-if="moveProps" v-bind="attrs" v-on="on" class="cursor-pointer work-space">To do</span>
				<v-btn v-else v-bind="attrs" v-on="on" depressed width="100%"
					><v-icon left>mdi-arrow-right</v-icon>Move</v-btn
				>
			</template>
			<v-toolbar color="blue darken-4" dark>
				<div class="text-h6 text-center title_card">Move card</div>
				<v-spacer></v-spacer>
				<v-btn class="me-1" @click="moveCard = false" small depressed icon
					><v-icon>mdi-close</v-icon></v-btn
				>
			</v-toolbar>
			<v-list class="px-2 card-member">
				<div class="">
					<label class="input-label"><v-icon small>mdi-stars</v-icon>Suggested</label>
					<v-btn depressed class="w-100 mb-2"><v-icon left>mdi-arrow-left</v-icon>Doing</v-btn>
					<v-btn depressed class="w-100 mb-2"><v-icon left>mdi-arrow-left</v-icon>Done</v-btn>
				</div>
				<div class="custom_field pt-2 pb-3">
					<label class="input-label">Select destination</label>
					<v-select
						hide-details
						v-model="designation"
						:items="designationList"
						attach
						chips
						label="Project"
						class="rounded"
						style="background-color: rgb(238, 231, 231)"
					></v-select>
					<div class="d-flex pt-2">
						<v-select
							hide-details
							v-model="list"
							:items="moveList"
							attach
							chips
							label="List"
							class="rounded me-2"
							style="background-color: rgb(238, 231, 231)"
						></v-select>
						<v-select
							hide-details
							v-model="position"
							:items="positionList"
							attach
							chips
							label="Position"
							class="rounded"
							style="background-color: rgb(238, 231, 231)"
						></v-select>
					</div>
				</div>
				<div class="">
					<v-btn color="blue darken-2" class="white--text">Move</v-btn>
				</div>
			</v-list>
		</v-menu>
	</div>
</template>
<script>
export default {
	name: "MemberCard",
	props: ["moveProps"],
	data() {
		return {
			moveCard: false,
			designation: "John doe",
			list: "To do",
			position: 1,
			designationList: ["John doe", "Maxwell"],
			positionList: [1, 2, 3, 4],
			moveList: ["To do", "Doing", "done", "buzz"],
		};
	},
	components: {
		// TextInput,
	},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	justify-content: flex-start !important;
	text-transform: capitalize !important;
	font-size: 12px !important;
}
.work-space:hover {
	text-decoration: underline;
}
</style>
