<template>
	<div class="cover add-card_btn">
		<div class="" :style="{ backgroundColor: selectedColor }">
			<div style="position: relative">
				<v-menu
					:nudge-width="300"
					max-width="300"
					v-model="coverCard"
					offset-y
					:close-on-content-click="false"
				>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" absolute right class="black--text mt-3" depressed
							><v-icon>mdi-television</v-icon>Cover</v-btn
						>
					</template>
					<v-toolbar color="blue darken-4" dark>
						<div class="text-h6 text-center title_card">Cover</div>
						<v-spacer></v-spacer>
						<v-btn class="me-1" @click="coverCard = false" small depressed icon
							><v-icon>mdi-close</v-icon></v-btn
						>
					</v-toolbar>
					<v-list class="px-2 card-member">
						<div class="pb-2 cover-size">
							<label class="input-label">Size</label>
							<v-btn-toggle v-model="cover_size" tile group divided colo blue variant="plain" mandatory>
								<v-btn color="" x-large height="64" variant="flat">
									<v-img :src="photos" height="58" width="110" cover class="d-flex align-center"></v-img>
								</v-btn>

								<v-btn x-large color="" variant="flat" height="64">
									<v-img :src="photos" height="58" width="110"></v-img>
								</v-btn>
							</v-btn-toggle>
							<v-btn depressed class="w-100">Remove cover</v-btn>
						</div>
						<div class="pb-2 cover-size" v-if="cover_size">
							<label class="input-label">Text color</label>
							<v-btn-toggle v-model="text_color" tile group divided colo blue variant="plain" mandatory>
								<v-btn color="" x-large height="44" variant="flat">
									<v-img
										src="https://cdn.vuetifyjs.com/docs/images/cards/purple-flowers.jpg"
										height="40"
										width="110"
										class="d-flex align-center"
									>
										<span class="white--text">Project Planing</span>
									</v-img>
								</v-btn>

								<v-btn x-large color="" variant="flat" height="44">
									<v-img
										class="d-flex align-center"
										src="https://picsum.photos/350/165?random"
										height="40"
										width="110"
									>
										<span class="black--text">Project Planing</span>
									</v-img>
								</v-btn>
							</v-btn-toggle>
						</div>
						<div class="pb-2">
							<label class="input-label">Colors</label>
							<v-color-picker
								hide-canvas
								hide-inputs
								hide-mode-switch
								hide-sliders
								show-swatches
								swatches-max-height="75"
								v-model="selectedColor"
							></v-color-picker>
						</div>
						<div class="pb-2">
							<label class="input-label">Attachments</label>
							<v-img
								src="https://picsum.photos/350/165?random"
								class="rounded"
								height="60"
								width="60"
							></v-img>
							<div class="pt-2">
								<v-btn class="w-100 my-2" @click="choseFile()" depressed>Upload a cover image</v-btn>
								<input type="file" hidden ref="chooe_file" />
								<span style="font-size: 11px">Tip: Drag an image on to the card to upload it.</span>
							</div>
						</div>
						<div class="pb-2 cover-size">
							<label class="input-label">Photos from Unsplash</label>
							<v-btn-toggle v-model="photos" tile group divided colo blue variant="plain" mandatory>
								<v-btn
									color=""
									x-large
									height="55"
									value="https://cdn.vuetifyjs.com/docs/images/cards/purple-flowers.jpg"
									variant="flat"
								>
									<v-img
										src="https://cdn.vuetifyjs.com/docs/images/cards/purple-flowers.jpg"
										height="48"
										width="70"
									></v-img>
								</v-btn>

								<v-btn
									x-large
									color=""
									value="https://cdn.vuetifyjs.com/docs/images/cards/hands.jpg"
									variant="flat"
									height="55"
								>
									<v-img
										src="https://cdn.vuetifyjs.com/docs/images/cards/hands.jpg"
										height="48"
										width="70"
									></v-img>
								</v-btn>
								<v-btn
									x-large
									color=""
									value="https://picsum.photos/350/165?random"
									variant="flat"
									height="55"
								>
									<v-img src="https://picsum.photos/350/165?random" height="48" width="70"></v-img>
								</v-btn>
							</v-btn-toggle>
							<v-btn depressed class="w-100 mb-3 mt-2">Search for photos</v-btn>
							<span style="font-size: 12px"
								>By using images from Unsplash, you agree to their
								<v-btn style="height: 14px" plain text color="blue" depressed>license</v-btn> and<v-btn
									color="blue"
									plain
									depressed
									text
									>Terms of Service</v-btn
								></span
							>
						</div>
					</v-list>
				</v-menu>
			</div>
			<v-img height="200px" width="200" class="mx-auto" :src="photos"> </v-img>
		</div>
	</div>
</template>
<script>
export default {
	name: "CoverCardDialog",
	// props: {
	// 	color: {
	// 		type: String,
	// 		default: "#f44336",
	// 	},
	// },
	data() {
		return {
			coverCard: false,
			title: "",
			copy_items: "",
			toggle_none: null,
			cover_size: [0],
			text_color: [0],
			photos: "https://cdn.vuetifyjs.com/docs/images/cards/purple-flowers.jpg",
			selectedColor: "#f44336",
			copyitemsList: ["None", "Project Planing", "Project Planing2", "Project Planing3"],
			images: [
				{ name: "Image 1", url: "https://picsum.photos/350/165?random" },
				{ name: "Image 2", url: "https://picsum.photos/350/165?random" },
				// Add more image objects as needed
			],
			selectedImage: null,
		};
	},
	methods: {
		choseFile() {
			this.$nextTick(() => {
				this.$refs.chooe_file.click();
				console.log(this.$refs.chooe_file);
			});
		},
		selectImage(image) {
			this.selectedImage = image;
		},
	},
	components: {},
};
</script>
<style scoped>
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
	text-transform: capitalize !important;
}
.card-member button {
	text-transform: capitalize !important;
	font-size: 12px !important;
}
.cover-size button {
	padding: 2px !important;
}
.cover-size button .v-image {
	border-radius: 6px !important;
}
.cover-size
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--x-large {
	border: 2px solid #0c66e4 !important;
	border-radius: 3px;
}
.image-container {
	display: flex;
	flex-wrap: wrap;
}

.image-container img {
	width: 100px;
	height: 50px;
	object-fit: cover;
	cursor: pointer;
	margin: 5px;
	border: 2px solid transparent;
	border-radius: 5px;
	padding: 2px;
}

.image-container img.selected {
	border-color: #0d47a1; /* You can customize the border color for the selected image */
}
</style>
