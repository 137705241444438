<template>
	<div class="customer-template-setting custom_font_family">
		<!-- <div class="" :style="{ backgroundColor: selectedColor }">
			<div style="position: relative">
				<CoverCardDialog :color="selectedColor" @color-change="onColorChange"></CoverCardDialog>
			</div>
			<v-img
				height="200px"
				width="200"
				class="mx-auto"
				src="https://cdn.vuetifyjs.com/docs/images/cards/purple-flowers.jpg"
			>
			</v-img>
		</div> -->
		<CoverCardDialog></CoverCardDialog>
		<v-card-text class="px-0 d-flex align-baseline">
			<div class="font-weight-bold mb-0 d-flex align-center">
				<v-icon color="black" class="me-2" size="20">mdi-devices</v-icon>
			</div>
			<div class="w-100 task-title">
				<!-- <h3>Project planing</h3> -->
				<div>
					<h3 v-if="!isEditing" @click="startEditing">{{ textTitle }}</h3>
					<TextInput
						hide-details
						class="mt-0 edit_field"
						v-else
						v-model="textTitle"
						@blur="stopEditing"
						@keydown.enter="stopEditing"
					></TextInput>
				</div>
				<div class="d-flex">
					<span class="mr-1">in list</span> <MoveCard :moveProps="moveCard"></MoveCard>
					<v-icon v-if="watchClick" size="18" class="ms-1">mdi-eye-outline</v-icon>
				</div>
			</div>
		</v-card-text>
		<v-row>
			<v-col cols="12" md="9" class="">
				<div class="ms-7 d-flex flex-wrap">
					<div class="pe-3 py-2">
						<div class="list_title">Members</div>
						<MembersAddDialog></MembersAddDialog>
					</div>
					<div class="pe-3 py-2">
						<div class="list_title">Labels</div>
						<div class="d-flex">
							<template v-for="(row, index) in labelListItem">
								<v-tooltip bottom :key="index">
									<template v-slot:activator="{ on, attrs }">
										<v-btn depressed class="mr-1" :color="row.color" v-bind="attrs" v-on="on">
											{{ row.title }}
										</v-btn>
									</template>
									<span>Color: {{ row.color }}, title: "{{ row.title }}"</span>
								</v-tooltip>
							</template>
							<!-- <v-tooltip bottom>
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed v-bind="attrs" v-on="on" class="mr-1" elevation="0" color="orange">
										On Page
									</v-btn>
								</template>
								<span>Color: orange, title: "Off Page"</span>
							</v-tooltip> -->
							<LabelCard :createLabel="openLabelcard" @add-label="onClickLabel"></LabelCard>
						</div>
					</div>
					<div class="pe-3 py-2">
						<div class="list_title">Notifications</div>
						<v-btn depressed @click="watchClick = !watchClick">
							<v-icon color="grey" left> mdi-eye-outline </v-icon>
							Watching
							<v-icon v-if="watchClick" right color="grey"> mdi-checkbox-marked </v-icon>
						</v-btn>
					</div>
					<div class="pe-3 py-2">
						<div class="list_title">Due date</div>
						<div class="d-flex align-center">
							<!-- <v-checkbox
								class="mb-1"
								small
								dense
								depressed
								hide-details
								v-model="dueDateCheckBox"
							></v-checkbox> -->
							<v-menu
								v-model="duedateMenu"
								offset-y
								:close-on-content-click="false"
								top
								class="due-date-menu"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-btn depressed v-bind="attrs" v-on="on">
										{{ due_date }}
										<v-icon color="grey" right>mdi-chevron-down</v-icon>
									</v-btn>
								</template>
								<v-toolbar color="blue darken-4" dark>
									<div class="">
										<div class="text-h6">Dates</div>
									</div>
									<v-btn absolute top right class="me-1" @click="duedateMenu = false" small depressed icon
										><v-icon>mdi-close</v-icon></v-btn
									>
								</v-toolbar>
								<v-list class="px-3">
									<v-form>
										<div class="mb-2">
											<v-date-picker range no-title v-model="due_date" show-adjacent-months></v-date-picker>
										</div>
										<div class="mb-2">
											<label class="input-label">Start date</label>
											<div class="d-flex align-center">
												<v-checkbox
													class="mb-0 mt-2 my-0"
													@click="disabled = (disabled + 1) % 2"
													hide-details
													v-model="startDateCheck"
												></v-checkbox>
												<TextInput
													:disabled="!disabled == 1"
													style="max-width: 100px"
													v-model="dueDateList.start_date_field"
													hide-details
													outlined
												></TextInput>
											</div>
										</div>
										<div class="mb-2">
											<label class="input-label">Due date</label>
											<div class="d-flex align-center">
												<v-checkbox
													@click="disabled_due = (disabled_due + 1) % 2"
													class="mb-0 mt-2 mb-0"
													hide-details
													v-model="dueDateCheck"
												></v-checkbox>
												<TextInput
													:disabled="!disabled_due == 1"
													style="max-width: 100px; margin-right: 8px"
													v-model="dueDateList.start_date_field"
													hide-details
													placeholder=""
													outlined
												></TextInput>
												<TextInput
													:disabled="!disabled_due == 1"
													style="max-width: 100px"
													v-model="dueDateList.due_time_field"
													hide-details
													outlined
												></TextInput>
											</div>
										</div>
										<div class="mb-2">
											<label class="input-label">Set due date reminder</label>
											<v-select
												hide-details
												outlined
												v-model="dueDateList.due_date_reminder"
												:items="reminderDateList"
												:rules="[(v) => !!v || 'Set due date reminder']"
												required
											></v-select>
											<span
												>Reminders will be sent to all members<br />
												and watchers of this card.</span
											>
										</div>
										<div class="mb-2">
											<v-btn class="white--text d-block w-100 mb-2" depressed color="blue darken-4"
												>Save</v-btn
											>
											<v-btn class="d-block w-100" depressed>Cancel</v-btn>
										</div>
									</v-form>
								</v-list>
							</v-menu>
						</div>
					</div>
				</div>
				<!-- Description section start -->
				<v-card-text class="px-0 pb-0 d-flex align-baseline">
					<div class="font-weight-bold mb-0 d-flex align-center">
						<v-icon color="black" class="me-2" size="24">mdi-playlist-edit</v-icon>
					</div>
					<div class="d-flex justify-content-between w-100">
						<h5>Description</h5>
						<template v-if="!editable">
							<v-btn @click="startEditDesc" depressed>Edit</v-btn>
						</template>
					</div>
				</v-card-text>
				<v-row class="ms-4">
					<v-col cols="12">
						<template v-if="!editable">
							<div @click="startEditDesc">{{ description }}</div>
						</template>
						<template v-else>
							<ckeditor
								:editor="editor"
								@blur="saveEditDesc"
								v-model="description"
								:config="editorConfig"
							></ckeditor>
							<div class="d-flex mt-2">
								<v-btn class="me-2" depressed color="blue darken-4 white--text" @click="saveEditDesc">
									Save
								</v-btn>
								<v-btn @click="saveEditDesc" depressed>Cancel</v-btn>
							</div>
						</template>
					</v-col>
				</v-row>
				<!-- Description section end -->

				<!-- Attachments section start -->
				<v-card-text class="px-0 pb-0 d-flex align-baseline">
					<div class="font-weight-bold mb-0 d-flex align-center">
						<v-icon color="black" class="me-2" size="24">mdi-playlist-edit</v-icon>
					</div>
					<div class="d-flex justify-content-between w-100">
						<h5>Attachments</h5>
						<template>
							<!-- <v-btn depressed>Add</v-btn> -->
							<AttachmentCard :addattachment="openAttachment"></AttachmentCard>
						</template>
					</div>
				</v-card-text>
				<v-row class="ms-4">
					<v-col col="12" @click="imgPopupdialog = true">
						<AddAttachment></AddAttachment>
					</v-col>
				</v-row>
				<!-- Attachments section end -->

				<!-- Checklist items section start -->
				<v-card-text class="px-0 py-2 d-flex align-baseline">
					<div class="font-weight-bold mb-0 d-flex align-center">
						<v-icon color="black" class="me-2" size="20">mdi-checkbox-multiple-marked-outline</v-icon>
					</div>
					<div class="d-flex justify-content-between w-100" v-if="!isEditingCheck">
						<h5 @click="startEditingCheck">{{ editCheckText }}</h5>
						<div>
							<template v-if="true">
								<v-btn class="mr-2" depressed>hide checked items</v-btn>
							</template>
							<template v-else>
								<v-btn depressed>Show checked items</v-btn>
							</template>
							<v-btn depressed>Delete</v-btn>
						</div>
					</div>
					<div v-else>
						<TextInput
							hide-details
							class="mt-0 mb-2 edit_field"
							v-model="editCheckText"
							@blur="stopEditingCheck"
							@keydown.enter="stopEditingCheck"
						></TextInput>
						<v-btn @click="stopEditingCheck" color="blue darken-4" class="white--text me-2" depressed
							>Add</v-btn
						>
						<v-btn depressed @click="stopEditingCheck"><v-icon>mdi-close</v-icon></v-btn>
					</div>
				</v-card-text>
				<v-card-text class="px-0 pb-0 pt-2">
					<div class="d-flex align-center">
						<template>
							<span>0%</span>
						</template>
						<v-progress-linear class="ms-4" v-model="skill"></v-progress-linear>
					</div>
				</v-card-text>

				<v-row class="ms-5">
					<v-col cols="12" class="pt-0">
						<ChecklistItem></ChecklistItem>
					</v-col>
				</v-row>
				<!-- Checklist items section end -->

				<!-- Reminders section start -->
				<v-card-text class="px-0 py-2 d-flex align-baseline">
					<div class="font-weight-bold mb-0 d-flex align-center">
						<v-icon color="black" class="me-2" size="18">mdi-bell-ring-outline</v-icon>
					</div>
					<div class="d-flex justify-content-between w-100">
						<h5>Reminders</h5>
						<v-menu v-model="closeReminder" offset-y top v-if="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn v-bind="attrs" v-on="on" depresssed class="white--text" color="blue darken-4"
									>Create Reminder</v-btn
								>
							</template>
							<v-toolbar color="blue darken-4" dark>
								<div class="text-h6 title_card">Reminders</div>
								<v-spacer></v-spacer>
								<v-btn class="me-1" @click="closeReminder = false" small depressed icon
									><v-icon>mdi-close</v-icon></v-btn
								>
							</v-toolbar>
							<v-list class="px-3">
								<v-form>
									<div class="mb-2">
										<label class="input-label required">Date to be notified</label>
										<DatePicker
											:hideTopMargin="true"
											depressed
											:mandatory="true"
											placeholder="Date to be notified"
											v-model="notified_date"
											:default-date="reminderdata.notified_date"
											:rules="[vrules.required(notified_date, 'Date to be notified')]"
											:class="{
												required: !notified_date,
											}"
										></DatePicker>
									</div>
									<div class="mb-2">
										<label class="input-label required" required>Set reminder to</label>
										<v-select
											hide-details
											outlined
											v-model="reminderdata.reminder_name"
											:items="userList"
											:rules="[(v) => !!v || 'Set reminder to']"
											required
										></v-select>
									</div>
									<div class="mb-2">
										<label class="input-label required">Description</label>
										<v-textarea
											hide-details
											outlined
											row="4"
											placeholder="Description"
											v-model.trim="reminderdata.description"
										></v-textarea>
									</div>
									<div class="mb-2">
										<v-checkbox
											v-model="reminderdata.emailCheckBox"
											label="Send also an email for this reminder"
										></v-checkbox>
									</div>
									<div class="mb-2">
										<v-btn class="white--text" depressed color="blue darken-4">Create Reminder</v-btn>
									</div>
								</v-form>
							</v-list>
						</v-menu>
					</div>
				</v-card-text>
				<v-row class="ms-4">
					<v-col cols="12" class="py-0">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>Reminder for Brett Quigley on 2023-09-29 13:58:0</v-list-item-title>
								<v-list-item-subtitle>description text</v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<div class="d-flex">
									<v-btn icon small class="me-2">
										<v-icon @click="closeReminder = true" size="15" color="grey lighten-1">mdi-pencil</v-icon>
									</v-btn>
									<v-btn icon small>
										<v-icon color="grey lighten-1">mdi-close</v-icon>
									</v-btn>
								</div>
							</v-list-item-action>
						</v-list-item>
					</v-col>
				</v-row>
				<v-card-text class="px-0 py-2">
					<div class="d-flex align-baseline">
						<div class="font-weight-bold mb-0 d-flex align-center">
							<v-icon color="black" class="me-2" size="22">mdi-format-list-text</v-icon>
						</div>
						<div class="d-flex justify-content-between w-100">
							<h5>Activity</h5>
							<v-btn depressed @click="showHide = !showHide"
								><span>{{ showHide ? "Show" : "Hide" }}</span> details</v-btn
							>
						</div>
					</div>
					<v-row>
						<v-col cols="12" md="1" class="pe-0">
							<v-avatar color="orange lighten-2" class="black--text text-h6 me-2" size="32"
								><span>JD</span></v-avatar
							>
						</v-col>
						<v-col cols="12" md="11" class="comments_editor">
							<template v-if="clickcomment">
								<v-card>
									<v-card-text @click="clickcomment = false" class="py-2">Write a comments..</v-card-text>
								</v-card>
							</template>
							<template v-else>
								<ckeditor :editor="editor" v-model="comments" :config="editorConfig"></ckeditor>
								<v-btn
									@click="addActivityItem()"
									color="blue darken-4"
									class="white--text mt-2"
									:disabled="disabledBtn"
									>Save</v-btn
								>
							</template>
						</v-col>
						<v-col cols="12" md="12" class="py-0" v-for="(row, index) in activityDataList" :key="index">
							<v-row v-if="!row.isEditing">
								<v-col cols="12" md="1" class="pe-0">
									<v-avatar color="orange lighten-2" class="black--text text-h6 me-2" size="32"
										><span>JD</span></v-avatar
									>
								</v-col>
								<v-col cols="12" md="11">
									<div class="">
										<div>
											<b>John doe</b
											><v-btn
												class="text_underline"
												style="
													font-size: 12px;
													opacity: 1 !important;
													font-weight: 400 !important;
													height: 15px !important;
												"
												depressed
												plain
												>yesterday at 5:26 PM</v-btn
											>
										</div>
										<template v-if="row.editativity">
											<v-card class="field_text">
												<v-card-text class="py-2 my-1" v-html="row.comments"></v-card-text>
											</v-card>
											<div>
												<v-btn
													style="min-width: 20px !important; padding: 0px !important; font-size: 12px"
													plain
													><v-icon size="18">mdi-emoticon-sad-outline</v-icon></v-btn
												>
												<span><v-icon>mdi-circle-small</v-icon></span>
												<v-btn
													style="min-width: 20px !important; padding: 0px !important; font-size: 12px"
													plain
													class="text-decoration-underline"
													@click="row.editativity = false"
													>Edit</v-btn
												>
												<span><v-icon>mdi-circle-small</v-icon></span>
												<v-menu v-model="row.deleteActivityBox" offset-y top :close-on-content-click="false">
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															v-bind="attrs"
															v-on="on"
															style="min-width: 20px !important; padding: 0px !important; font-size: 12px"
															class="text-decoration-underline"
															plain
															>Delete</v-btn
														>
													</template>
													<v-toolbar color="blue darken-4" dark>
														<div class="text-h6 text-center">Reminders</div>
														<v-spacer></v-spacer>
														<v-btn class="me-1" @click="row.deleteActivityBox = false" small depressed icon
															><v-icon>mdi-close</v-icon></v-btn
														>
													</v-toolbar>
													<v-list class="px-2">
														<v-list-item>
															<v-list-item-content>
																Deleting a comment is forever. There is no<br />
																undo.
															</v-list-item-content>
														</v-list-item>
														<v-list-item>
															<v-list-item-content>
																<v-btn color="red" class="white--text my-2" block>Delete comment</v-btn>
															</v-list-item-content>
														</v-list-item>
													</v-list>
												</v-menu>
											</div>
										</template>
										<template v-else>
											<ckeditor :editor="editor" v-model="comments" :config="editorConfig"></ckeditor>
											<div class="mt-2">
												<v-btn @click="row.editativity = true" color="blue darken-4" class="white--text mr-2"
													>Save</v-btn
												>
												<v-btn depressed @click="row.editativity = true">Discard details</v-btn>
											</div>
										</template>
									</div>
								</v-col>
							</v-row>
						</v-col>
						<template v-if="!showHide">
							<v-col cols="12" md="1" class="pe-0">
								<v-avatar color="orange lighten-2" class="black--text text-h6 me-2" size="32"
									><span>JD</span></v-avatar
								>
							</v-col>
							<v-col cols="12" md="11">
								<div class="">
									<div><b>John doe</b><span class="ps-1">marked incomplete in the card</span></div>
									<span class="text_underline cursor-pointer">yesterday at 5:26 PM</span>
								</div>
							</v-col>
							<v-col cols="12" md="1" class="pe-0">
								<v-avatar color="orange lighten-2" class="black--text text-h6 me-2" size="32"
									><span>JD</span></v-avatar
								>
							</v-col>
							<v-col cols="12" md="11">
								<div class="">
									<div><b>John doe</b><span class="ps-1">marked incomplete in the card</span></div>
									<span class="text_underline cursor-pointer">yesterday at 5:26 PM</span>
									<div>
										<div class="pointer" @click="imgPopupdialog = true">
											<v-img
												class="border"
												lazy-src="https://picsum.photos/id/11/10/6"
												max-height="250"
												max-width="250"
												src="https://picsum.photos/id/11/500/300"
											></v-img>
										</div>
										<span class="text-decoration-underline cursor-pointer">Reply</span>
									</div>
								</div>
							</v-col>
						</template>
					</v-row>
				</v-card-text>
			</v-col>
			<v-col cols="12" md="3" class="add-card_btn">
				<div class="list_title">Add to card</div>
				<div class="my-2">
					<CustomerCard></CustomerCard>
				</div>
				<div class="my-2">
					<LeadCard></LeadCard>
				</div>
				<div class="my-2">
					<ProjectCard></ProjectCard>
				</div>
				<div class="my-2">
					<MeetingCard></MeetingCard>
				</div>
				<div class="my-2">
					<Membercard></Membercard>
				</div>
				<div class="my-2">
					<LabelCard></LabelCard>
				</div>
				<div class="my-2">
					<AddChecklist></AddChecklist>
				</div>
				<div class="my-2">
					<DatesCad></DatesCad>
				</div>
				<div class="my-2">
					<AttachmentCard></AttachmentCard>
				</div>
				<div class="my-2">
					<MoveCard></MoveCard>
				</div>
				<div class="my-2">
					<CopyCard></CopyCard>
				</div>
				<div class="my-2">
					<ShareCard></ShareCard>
				</div>
				<div class="my-2">
					<v-btn depressed width="100%"><v-icon left>mdi-delete</v-icon>Delete</v-btn>
				</div>
			</v-col>
		</v-row>
		<v-dialog max-width="400" v-model="imgPopupdialog" @click.stop="imgPopupdialog = true">
			<v-card elevation="0">
				<v-img
					lazy-src="https://picsum.photos/id/11/10/6"
					src="https://picsum.photos/id/11/500/300"
				></v-img>
			</v-card>
		</v-dialog>
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import ChecklistItem from "@/view/components/ChecklistItem";
import MembersAddDialog from "@/view/components/MembersAddDialog";
import CoverCardDialog from "@/view/components/CoverCardDialog";
import AddAttachment from "@/view/components/AddAttachment";
import CustomerCard from "@/view/components/CustomerCard";
import LeadCard from "@/view/components/LeadCard";
import ProjectCard from "@/view/components/ProjectCard";
import MeetingCard from "@/view/components/MeetingCard";
import Membercard from "@/view/components/Membercard";
import AddChecklist from "@/view/components/AddChecklist";
import LabelCard from "@/view/components/LabelCard";
import DatesCad from "@/view/components/DatesCad";
import AttachmentCard from "@/view/components/AttachmentCard";
import MoveCard from "@/view/components/MoveCard";
import CopyCard from "@/view/components/CopyCard";
import ShareCard from "@/view/components/ShareCard";
//import AttachmentTaskBtn from "@/view/components/AttachmentTaskBtn";
export default {
	name: "TaskAddItemDialog",

	components: {
		DatePicker,
		TextInput,
		ChecklistItem,
		MembersAddDialog,
		AddAttachment,
		CustomerCard,
		LeadCard,
		ProjectCard,
		MeetingCard,
		Membercard,
		AddChecklist,
		LabelCard,
		DatesCad,
		AttachmentCard,
		MoveCard,
		CopyCard,
		ShareCard,
		CoverCardDialog,
		//AttachmentTaskBtn,
	},
	data() {
		return {
			//selectedColor: "#f44336",
			moveProps: false,
			moveCard: true,
			openLabelcard: true,
			openAttachment: true,
			isEditing: false,
			textTitle: "Project Planing",
			isEditingCheck: false,
			editCheckText: "Checklist items",
			attachmentsDialog: false,
			showHide: false,
			clickcomment: true,
			transparent: "rgba(255, 255, 255, 0)",
			closeReminder: false,
			imgPopupdialog: false,
			detelteAttachmentEvent: false,
			duedateMenu: false,
			editable: false,
			due_date: "2019-09-10', '2019-09-20",
			editorConfig: {},
			description: "description",
			editor: ClassicEditor,
			//editorData: "<p>Description</p>",
			watchClick: true,
			//dueDateCheckBox: false,
			addItemChecklist: false,
			notified_date: null,
			disabled: 0,
			disabled_due: 0,
			activityDataList: [],
			labelListItem: [
				{
					id: 1,
					color: "green",
					title: "On page",
					checkLabels: true,
				},
			],
			comments: "",
			addcardItem: [
				{
					id: 1,
					text: "Members",
					icon: "mdi-account-outline",
					value: "members",
				},
				{
					id: 1,
					text: "Labels",
					icon: "mdi-label-outline",
					value: "labels",
				},
				{
					id: 1,
					text: "Checklist",
					icon: "mdi-checkbox-marked-circle-outline",
					value: "checklist",
				},
				{
					id: 1,
					text: "Dates",
					icon: "mdi-clock-outline",
					value: "dates",
				},
				{
					id: 1,
					text: "Attachments",
					icon: "mdi-attachment",
					value: "attachments",
				},
				{
					id: 1,
					text: "Move",
					icon: "mdi-arrow-right",
					value: "move",
				},
				{
					id: 1,
					text: "Copy",
					icon: "mdi-content-copy",
					value: "copy",
				},
			],
			reminderdata: {
				setReminderPerson: null,
				description: null,
				reminderdata: false,
			},
			show_hideCover: false,
			dueDateCheck: false,
			startDateCheck: false,
			dueDateList: {
				due_time_field: "10:30 AM",
				start_date_field: "2019-09-10",
				due_date_reminder: "1 Day before",
			},
			reminderDateList: ["None", "1 Day before", "2 Day before", "3 Day before"],
			userList: ["Brett Quigley", "Juwan Bosco"],
			skill: 0,
			zoom: {
				client_id: null,
				client_secret: null,
			},
			titleFieldData: [
				{
					comments: null,
				},
			],
			//createBackLinkDialog: false,
		};
	},
	methods: {
		// onColorChange(newColor) {
		// 	this.selectedColor = newColor;
		// },
		startEditing() {
			this.isEditing = true;
		},
		stopEditing() {
			this.isEditing = false;
		},
		startEditDesc() {
			this.editable = true;
		},
		saveEditDesc() {
			this.editable = false;
		},
		startEditingCheck() {
			this.isEditingCheck = true;
		},
		stopEditingCheck() {
			this.isEditingCheck = false;
		},
		addCardBtnEvent(row) {
			if (row.value == "members") {
				this.attachmentsDialog = true;
			}
		},
		addActivityItem() {
			this.clickcomment = true;
			if (!this.disabledBtn) {
				this.activityDataList.push({
					comments: this.comments,
					isEditing: false,
					editativity: true,
					deleteActivityBox: false,
				});
			}
			this.comments = "";
		},

		addCheckListEvent() {
			const len = this.titleFieldData.length;

			if (len == 0) {
				this.titleFieldData.push({
					comments: null,
				});
			} else if (this.titleFieldData[len - 1].comments) {
				this.titleFieldData.push({
					comments: null,
				});
			}
		},
		goBack() {
			this.$router.go(-1);
		},
		createBacklibrary() {
			this.createBackLinkDialog = true;
		},
		onClickLabel(row) {
			console.log("row", row);
			// Check if labelListItem already contains an item with the same id
			const existingIndex = this.labelListItem.findIndex((item) => item.id === row.id);

			if (existingIndex !== -1) {
				// If the item exists, remove it
				this.labelListItem.splice(existingIndex, 1);
			} else {
				// If the item doesn't exist, add it to labelListItem
				this.labelListItem.push({
					id: row.id,
					title: row.title,
					color: row.color,
					checkLabels: row.checkLabels,
				});
			}
		},
	},
	computed: {
		disabledBtn() {
			return !this.comments;
		},
	},
};
</script>
<style scoped>
.custom_font_family {
	/* color: #172b4d; */
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400;
	line-height: 20px;
}
.show-btns {
	color: rgb(27, 25, 25) !important;
}
.customer-template-setting h5 {
	margin-bottom: 0px;
}
.customer-template-setting button {
	text-transform: capitalize !important;
}
.add-card_btn button {
	justify-content: flex-start !important;
	color: #172b4d !important;
	opacity: 1 !important;
}
.text_underline {
	opacity: 1 !important;
}
.text_underline:hover {
	text-decoration: underline;
}
.hoverlist:hover {
	background-color: #e4e6ea;
}
.task-title .edit_field {
	border: 1px solid #000;
	outline: none;
}
.edit_field {
	border: 1px solid #000;
	outline: none;
}
.field_text p {
	margin-bottom: 0px !important;
}
.list_title {
	font-weight: 500;
	font-size: 12px;
}
.work-space:hover {
	text-decoration: underline;
}
</style>
